@use 'src/scss/themes/_custom-color.scss' as customcolor;

/* ---------------
    按鈕類
 ---------------- */

/* 包含圖示的居中按鈕 */
.sent {
  margin: 3rem auto;
  width: 45%;
  max-width: 240px;

  button {
    width: 100%;
    height: 44px;

    i,
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    span {
      display: inline-block;
    }
  }

  button + button {
    margin-top: 24px !important;
  }
}

/* 通常用於 step切換步驟時 */
.btn_back {
  float: left;
  margin-top: 8px !important;
}

.btn_next {
  float: right;
  margin-top: 8px !important;
}

.table_button {
  vertical-align: middle;
  text-align: center;
}

.btn_border {
  border: 1px #fafafa solid !important;
  border-radius: 4px;
  color: #fafafa;
}

td .mat-mdc-icon-button + .mat-mdc-icon-button {
  margin-left: 16px;
}

//校正按鈕accent顏色
.mat-mdc-unelevated-button,
.mat-mdc-raised-button {
  &.mat-accent {
    .mdc-button__label {
      color: #fff;
    }
  }
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  &.mat-accent {
    .mat-icon {
      color: #fff;
    }
  }
}
