@use 'src/scss/themes/_custom-color.scss' as customcolor;

.td_photo {
  width: 56px;
  height: 56px;
  object-fit: cover;
  background-color: #faf8f6;
  margin: 8px 0;
}

.td_photo img {
  max-width: 100%;
  max-height: 100%;
}

.table-rwd-title {
  background-color: customcolor.$system-lighter;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

tr.table-hover-row:hover {
  background: whitesmoke !important;
  cursor: pointer;
}

tr.table-hover-row:active {
  background: #efefef !important;
}

.table-hover-row td {
  border-bottom-width: 0 !important;
}

.table-underline {
  tr td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  tr + tr {
    td {
      border-top: 1px solid customcolor.$system-divider !important;
    }
  }
}

/* 表格查無結果時的樣式*/
.null-cell {
  text-align: center;
  white-space: nowrap;
  padding: 0 8px;
  height: 4.5rem;
}

@media (max-width: 780px) {
  .rwd-table {
    .mat-mdc-header-row {
      display: none;
    }

    .mat-mdc-row {
      flex-direction: column;
      align-items: start;
      padding: 8px 24px;

      .mat-mdc-cell {
        //額外寫「flex」是因為mat-cell與tr之間的樣式是利用mixin建立
        display: flex;
        padding: 0.5rem 1rem;

        &:first-of-type {
          padding-left: 8px;
        }
      }
    }
  }
}

// 有框線＆標題灰底的表格

.grayscale-table {
  border-top: 1px #e9e9e9 solid;
  border-left: 1px #e9e9e9 solid;
  border-right: 1px #e9e9e9 solid;

  .mat-mdc-header-cell {
    background-color: #e9e9e9 !important;
    text-align: center;
    white-space: nowrap;
    padding: 0 8px;
    border-bottom: 1px #e9e9e9 solid;
  }

  .mat-mdc-cell {
    text-align: center;
    white-space: nowrap;
    padding: 0 8px;
    height: 3rem;
  }

  .mat-mdc-cell + .mat-mdc-cell {
    border-left: 1px #e9e9e9 solid;
  }

  .border-right {
    border-right: 1px solid customcolor.$system-light !important;
  }

  .border-bottom {
    border-bottom: 1px solid customcolor.$system-light !important;
  }
}

.align-top {
  td {
    vertical-align: baseline;
  }
}

@media (max-width: 780px) {
  .table-underline {
    tr + tr {
      td {
        border-top: 0 !important;
      }
    }
  }

  .mobile-hightlight {
    background-color: customcolor.$system-lighter !important;
  }
}

.outline {
  border: 1px #e9e9e9 solid !important;
}
