@use '@angular/material' as mat;
@use 'src/scss/themes/_custom-theme.scss' as theme;
@use 'src/scss/themes/_custom-color.scss' as customcolor;

%h1 {
  @include mat.typography-level(theme.$custom-typography, 'headline-3');
}

%h2 {
  @include mat.typography-level(theme.$custom-typography, 'headline-4');
}

%h3 {
  @include mat.typography-level(theme.$custom-typography, 'headline-5');
}

%body {
  @include mat.typography-level(theme.$custom-typography, 'body-1');
}

%subtitle {
  @include mat.typography-level(theme.$custom-typography, 'subtitle-2');
}

%caption {
  @include mat.typography-level(theme.$custom-typography, 'caption');
}

* {
  font-family: 'Roboto', 'Noto Sans TC', sans-serif;
}

// 內文
body {
  @extend %body;
}

// 頁面標題
.title {
  @extend %h1;
  margin-bottom: 3rem;
  color: customcolor.$primary-dark;

  > span {
    color: customcolor.$primary-lighter;
    margin-left: 8px;
  }

  .mdc-icon-button {
    color: customcolor.$system-dark;
  }
}

// 大標：用於章節
.chapter {
  @extend %h2;
  margin-bottom: 1rem;
}

.mat-mdc-dialog-title {
  @extend %h2;
  margin: 0;
}

// 中標：用於小節
.section1 {
  @extend %h3;
  margin-top: 2rem;
  margin-bottom: 1rem;

  span.subtitle {
    margin-left: 1rem;
  }
}

// 用於會議記錄的小節
.meeting-section1 {
  @extend .section1;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-top: 3rem;
  background-color: #efebe9;
  border-left: 16px solid #795548;
  color: #795548;
}

// 小標：用於子分類或內文標題
.section2 {
  @extend %body;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.content-text {
  font-weight: 400 !important;
}

.subtitle {
  @extend %subtitle;
  color: customcolor.$system-dark;
}

td,
.mat-mdc-cell {
  @extend %body;
}

th,
.mat-mdc-header-cell,
code,
.bullet {
  @extend %caption;
}

p {
  text-align: justify;
}

span > p {
  margin: 0;
}

.justify {
  text-align: justify;
}

/* 標籤設定 */
.mark-layout {
  padding: 4px;
}

.tag {
  display: inline-block;
  background-color: customcolor.$system-divider;
  color: customcolor.$system-dark;
  padding: 0px 4px;
}
