/* margin設定 */
.spac-top-0 {
  margin-top: 0 !important;
}

.spac-top-4 {
  margin-top: 4px !important;
}
.spac-bottom-4 {
  margin-bottom: 4px !important;
}
.spac-left-4 {
  margin-left: 4px !important;
}
.spac-right-4 {
  margin-right: 4px !important;
}
.spac-sides-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.spac-top-8 {
  margin-top: 8px !important;
}
.spac-bottom-8 {
  margin-bottom: 8px !important;
}
.spac-left-8 {
  margin-left: 8px !important;
}
.spac-right-8 {
  margin-right: 8px !important;
}
.spac-sides-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.spac-top-12 {
  margin-top: 12px !important;
}
.spac-bottom-12 {
  margin-bottom: 12px !important;
}
.spac-left-12 {
  margin-left: 12px !important;
}
.spac-right-12 {
  margin-right: 12px !important;
}
.spac-sides-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.spac-top-16 {
  margin-top: 16px !important;
}
.spac-bottom-16 {
  margin-bottom: 16px !important;
}
.spac-left-16 {
  margin-left: 16px !important;
}
.spac-right-16 {
  margin-right: 16px !important;
}
.spac-sides-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.spac-top-24 {
  margin-top: 24px !important;
}
.spac-bottom-24 {
  margin-bottom: 24px !important;
}
.spac-left-24 {
  margin-left: 24px !important;
}
.spac-right-24 {
  margin-right: 24px !important;
}
.spac-sides-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.spac-top-32 {
  margin-top: 32px !important;
}
.spac-bottom-32 {
  margin-bottom: 32px !important;
}
.spac-left-32 {
  margin-left: 32px !important;
}
.spac-right-32 {
  margin-right: 32px !important;
}
.spac-sides-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.spac-left-40 {
  margin-left: 40px !important;
}

.spac-top-44 {
  margin-top: 44px !important;
}
.spac-bottom-44 {
  margin-bottom: 44px !important;
}
.spac-left-44 {
  margin-left: 44px !important;
}
.spac-right-44 {
  margin-right: 44px !important;
}

.spac-top-3r {
  margin-top: 3rem !important;
}
.spac-bottom-3r {
  margin-bottom: 3rem !important;
}
.spac-left-3r {
  margin-left: 3rem !important;
}

.spac-top-64 {
  margin-top: 64px !important;
}
.spac-bottom-64 {
  margin-bottom: 64px !important;
}
.spac-left-64 {
  margin-left: 64px !important;
}
.spac-right-64 {
  margin-right: 64px !important;
}

.spac-top-6r {
  margin-top: 6rem !important;
}

.spac-bottom-6r {
  margin-bottom: 6rem !important;
}

/* padding設定 */
.pd-top-12 {
  padding-top: 12px !important;
}

.pd-8 {
  padding: 8px;
}

.pd-8-16 {
  padding: 8px 16px;
}

.pd-top-8 {
  padding-top: 8px !important;
}

.pd-bottom-8 {
  padding-bottom: 8px !important;
}

.spac-sides--16 {
  margin-left: -16px;
  margin-right: -16px;
}

/* 常用尺寸設定 */
.full {
  width: 100%;
}

.list_56 {
  height: 56px !important;
}

.list_72 {
  height: 56px !important;
}

/* flex 排版設定 */

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap24 {
  gap: 24px;
}

.flex_default {
  display: flex !important;
  flex-direction: row;
}

.column_ifmini_reverse {
  flex-direction: column-reverse !important;
}

.flex_row_start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.flex_row_start_center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex_row_end_center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex_row_center {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex_row_between_start {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.flex_row_between_end {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.flex_row_between_center {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

%flex_row_between_center {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100%;
}

.flex_row_stretch_start {
  display: flex !important;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
}

.flex_column_start {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex_column_start_center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex_column_center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* flex item 占比設定 */
.item0 {
  flex: 0 !important;
}

.item {
  flex: 1 !important;
}

//用來編號等較短的欄位
.item-short-right {
  width: 2rem;
  padding: 0 0.5rem;
  text-align: right;
}

.text-right {
  text-align: right;
}

.item-short {
  width: 5rem !important;
  padding: 0 0.5rem;
}

.item-short3-left {
  width: 3rem !important;
}

//設定span為塊元素
.inline-block {
  display: inline-block;
}

//用來顯示較長的固定欄位
.item-long-title {
  width: 12rem;
}

.item2 {
  flex: 2 !important;
}

.item3 {
  flex: 3 !important;
}

.item4 {
  flex: 4 !important;
}

.item5 {
  flex: 5 !important;
}

.item6 {
  flex: 6 !important;
}

.item7 {
  flex: 7 !important;
}

.item8 {
  flex: 8 !important;
}

/* 表格對齊 */
.align-top td {
  vertical-align: super;
}

// 強制標準體
.font-w-normal {
  font-weight: normal;
}

@media (max-width: 960px) {
  .flex_default {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .mobile-column {
    display: flex;
    flex-direction: column !important;
  }

  .mobile-column-end {
    flex-direction: column !important;
    align-items: flex-end !important;
  }
}
