.wd-1 {
  width: 1rem;
}

.wd-px-24 {
  width: 24px;
}

.wd-2 {
  width: 2rem;
}

.wd-2-5 {
  width: 2.5rem;
}

.wd-3 {
  width: 3rem;
}

.wd-3-5 {
  width: 3.5rem;
}

.wd-4 {
  width: 4rem;
}

.wd-5 {
  width: 5rem;
}

.wd-6 {
  width: 6rem;
}

.wd-8 {
  width: 8rem;
}

.wd-10 {
  width: 10rem;
}
