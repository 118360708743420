@charset "UTF-8";
/*
  定義主題調色盤
*/
/* 以下為系統常用：color */
.color-alert {
  color: orange !important;
}

.color-warn {
  color: #b71c1c !important;
}

.color-white {
  color: #fff;
}

.color-system {
  color: #9e9e9e !important;
}

.color-system-dark {
  color: #757575 !important;
}

.color-grey-white {
  color: #fafafa;
}

.color-grey-blue {
  color: #78909c;
}

.color-grey-red {
  color: #e38c7a !important;
}

.color-blue {
  color: #1565c0;
}

.color-accent {
  color: #78c6be !important;
}

.color-primary {
  color: #8d6e63 !important;
}

/* 以下為系統常用：background-color */
.bgcolor-primary {
  background-color: #8d6e63 !important;
}

.bgcolor-highlight {
  background-color: #fff8e1 !important;
}

.bgcolor-emphasize {
  background-color: #ffb300 !important;
}

.bgcolor-warn {
  background-color: #ffebee !important;
}

/*----- 單位 chip 顏色設定 -----*/
.topic {
  width: 16px;
  height: 44px;
  margin: auto 8px auto 0;
}

/* 霧峰家園 WF*/
.bgColor_WF {
  background-color: #91d4a9 !important;
}

/* 愛心家園 LH*/
.bgColor_LH {
  background-color: #ffa07d !important;
}

/* 啟智學園 SE */
.bgColor_SE {
  background-color: #db899d !important;
}

/* 雲端 CD */
.bgColor_CD {
  background-color: #f4ac23 !important;
}

/* 寶寶班 BABY */
.bgColor_BABY {
  background-color: #a8dff1 !important;
}

/* 稻香發展中心 RF */
.bgColor_RF {
  background-color: #9e9e9e !important;
}

/* 主要頁面 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  -ms-overflow-style: none;
  overflow: auto;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
}

.center {
  display: block;
  text-align: center !important;
}

.center h1,
.center h2 {
  width: 100%;
  text-align: center !important;
}

.title-tool {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.title-tool > button {
  margin-top: 0.25rem;
  margin-bottom: 1rem !important;
}
.title-tool .item {
  display: flex;
  align-items: flex-start;
}
.title-tool a {
  margin-top: 4px;
}

[hidden] {
  display: none !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

.icons {
  width: 24px;
}

.mat-mdc-chip {
  white-space: nowrap;
}

.mat-mdc-chip + .mat-mdc-chip {
  margin-left: 8px !important;
}

.nowrap {
  white-space: nowrap;
}

.mat-mdc-menu-panel {
  position: relative !important;
  overflow: unset !important;
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.clear {
  clear: both;
}

/* 排版 */
pre {
  display: block;
  white-space: pre-wrap !important;
  margin: 0px !important;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.mobile-display {
  display: none;
}

/* 在不同尺寸下的設定 */
@media (max-width: 600px) {
  .mobile-hidden {
    display: none !important;
  }
  .mobile-display {
    display: block;
  }
  .mobile-item {
    flex: 1 !important;
  }
  .mobile-full {
    width: 100%;
  }
  .mobile-full .mat-mdc-form-field {
    margin-bottom: 3rem;
  }
  .title-tool {
    flex-direction: column;
    gap: 0;
  }
}
.mat-mdc-tooltip {
  white-space: pre-line;
}

.mat-focused .mat-mdc-standard-chip {
  background-color: #bdbdbd !important;
}

.pointer {
  cursor: pointer !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mdc-switch {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-icon-button-state-layer-size: 48px;
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #795548;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #78c6be;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #795548;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #795548;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #78c6be;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #78c6be;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #78c6be;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #78c6be;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Noto Sans TC;
  --mat-option-label-text-line-height: 1.5;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Noto Sans TC;
  --mat-optgroup-label-text-line-height: 1.5;
  --mat-optgroup-label-text-size: 1rem;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #795548;
  --mat-text-button-state-layer-color: #795548;
  --mat-text-button-ripple-color: rgba(121, 85, 72, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #78c6be;
  --mat-text-button-state-layer-color: #78c6be;
  --mat-text-button-ripple-color: rgba(120, 198, 190, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #795548;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #78c6be;
  --mdc-filled-button-label-text-color: #000;
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #795548;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #78c6be;
  --mdc-protected-button-label-text-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #795548;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #795548;
  --mat-outlined-button-ripple-color: rgba(121, 85, 72, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #78c6be;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #78c6be;
  --mat-outlined-button-ripple-color: rgba(120, 198, 190, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
}

html {
  --mdc-text-button-label-text-font: Noto Sans TC;
  --mdc-text-button-label-text-size: 1rem;
  --mdc-text-button-label-text-weight: 400;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Noto Sans TC;
  --mdc-filled-button-label-text-size: 1rem;
  --mdc-filled-button-label-text-weight: 400;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Noto Sans TC;
  --mdc-outlined-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-weight: 400;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Noto Sans TC;
  --mdc-protected-button-label-text-size: 1rem;
  --mdc-protected-button-label-text-weight: 400;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary,
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #795548;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent,
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #78c6be;
  --mat-fab-foreground-color: #000;
  --mat-fab-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn,
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mdc-extended-fab-label-text-font: Noto Sans TC;
  --mdc-extended-fab-label-text-size: 1rem;
  --mdc-extended-fab-label-text-weight: 400;
}

html {
  --mdc-icon-button-state-layer-size: 48px;
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #795548;
  --mat-icon-button-state-layer-color: #795548;
  --mat-icon-button-ripple-color: rgba(121, 85, 72, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #78c6be;
  --mat-icon-button-state-layer-color: #78c6be;
  --mat-icon-button-ripple-color: rgba(120, 198, 190, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Noto Sans TC;
  --mat-standard-button-toggle-text-font: Noto Sans TC;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Noto Sans TC;
  --mat-card-subtitle-text-line-height: 1.5;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-weight: 400;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #78c6be;
  --mdc-checkbox-selected-hover-icon-color: #78c6be;
  --mdc-checkbox-selected-icon-color: #78c6be;
  --mdc-checkbox-selected-pressed-icon-color: #78c6be;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #78c6be;
  --mdc-checkbox-selected-hover-state-layer-color: #78c6be;
  --mdc-checkbox-selected-pressed-state-layer-color: #78c6be;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #795548;
  --mdc-checkbox-selected-hover-icon-color: #795548;
  --mdc-checkbox-selected-icon-color: #795548;
  --mdc-checkbox-selected-pressed-icon-color: #795548;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #795548;
  --mdc-checkbox-selected-hover-state-layer-color: #795548;
  --mdc-checkbox-selected-pressed-state-layer-color: #795548;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #795548;
  --mdc-chip-elevated-disabled-container-color: #795548;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #78c6be;
  --mdc-chip-elevated-disabled-container-color: #78c6be;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #795548;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(121, 85, 72, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(121, 85, 72, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(121, 85, 72, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #795548;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(121, 85, 72, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #fafafa;
  --mat-datepicker-calendar-date-selected-state-background-color: #78c6be;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(120, 198, 190, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #fafafa;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(120, 198, 190, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(120, 198, 190, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(120, 198, 190, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #78c6be;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Noto Sans TC;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 1rem;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 1rem;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Noto Sans TC;
  --mdc-dialog-supporting-text-line-height: 1.5;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Noto Sans TC;
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #795548;
  --mdc-filled-text-field-focus-active-indicator-color: #795548;
  --mdc-filled-text-field-focus-label-text-color: rgba(121, 85, 72, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #795548;
  --mdc-outlined-text-field-focus-outline-color: #795548;
  --mdc-outlined-text-field-focus-label-text-color: rgba(121, 85, 72, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(121, 85, 72, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.12;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #78c6be;
  --mdc-filled-text-field-focus-active-indicator-color: #78c6be;
  --mdc-filled-text-field-focus-label-text-color: rgba(120, 198, 190, 0.87);
  --mdc-outlined-text-field-caret-color: #78c6be;
  --mdc-outlined-text-field-focus-outline-color: #78c6be;
  --mdc-outlined-text-field-focus-label-text-color: rgba(120, 198, 190, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(120, 198, 190, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Noto Sans TC;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Noto Sans TC;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Noto Sans TC;
  --mat-form-field-container-text-line-height: 1.5;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 1rem;
  --mat-form-field-subscript-text-font: Noto Sans TC;
  --mat-form-field-subscript-text-line-height: 1.25;
  --mat-form-field-subscript-text-size: 0.8rem;
  --mat-form-field-subscript-text-weight: 500;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #795548;
}
.mat-icon.mat-accent {
  --mat-icon-color: #78c6be;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #795548;
  --mdc-radio-selected-hover-icon-color: #795548;
  --mdc-radio-selected-icon-color: #795548;
  --mdc-radio-selected-pressed-icon-color: #795548;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #78c6be;
  --mdc-radio-selected-hover-icon-color: #78c6be;
  --mdc-radio-selected-icon-color: #78c6be;
  --mdc-radio-selected-pressed-icon-color: #78c6be;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #795548;
  --mdc-checkbox-selected-hover-icon-color: #795548;
  --mdc-checkbox-selected-icon-color: #795548;
  --mdc-checkbox-selected-pressed-icon-color: #795548;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #795548;
  --mdc-checkbox-selected-hover-state-layer-color: #795548;
  --mdc-checkbox-selected-pressed-state-layer-color: #795548;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #78c6be;
  --mdc-checkbox-selected-hover-icon-color: #78c6be;
  --mdc-checkbox-selected-icon-color: #78c6be;
  --mdc-checkbox-selected-pressed-icon-color: #78c6be;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #78c6be;
  --mdc-checkbox-selected-hover-state-layer-color: #78c6be;
  --mdc-checkbox-selected-pressed-state-layer-color: #78c6be;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #795548;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #795548;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Noto Sans TC;
  --mdc-list-list-item-label-text-line-height: 1.5;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Noto Sans TC;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.25;
  --mdc-list-list-item-trailing-supporting-text-size: 0.8rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Noto Sans TC;
  --mat-menu-item-label-text-size: 1rem;
  --mat-menu-item-label-text-line-height: 1.5;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #795548;
  --mdc-linear-progress-track-color: rgba(121, 85, 72, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #78c6be;
  --mdc-linear-progress-track-color: rgba(120, 198, 190, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #795548;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #78c6be;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #795548;
  --mdc-radio-selected-hover-icon-color: #795548;
  --mdc-radio-selected-icon-color: #795548;
  --mdc-radio-selected-pressed-icon-color: #795548;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #795548;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #78c6be;
  --mdc-radio-selected-hover-icon-color: #78c6be;
  --mdc-radio-selected-icon-color: #78c6be;
  --mdc-radio-selected-pressed-icon-color: #78c6be;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #78c6be;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(121, 85, 72, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(120, 198, 190, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-trigger-text-font: Noto Sans TC;
  --mat-select-trigger-text-line-height: 1.5;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #795548;
  --mdc-slider-focus-handle-color: #795548;
  --mdc-slider-hover-handle-color: #795548;
  --mdc-slider-active-track-color: #795548;
  --mdc-slider-inactive-track-color: #795548;
  --mdc-slider-with-tick-marks-inactive-container-color: #795548;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #795548;
  --mat-slider-hover-state-layer-color: rgba(121, 85, 72, 0.05);
  --mat-slider-focus-state-layer-color: rgba(121, 85, 72, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #78c6be;
  --mat-slider-hover-state-layer-color: rgba(120, 198, 190, 0.05);
  --mat-slider-focus-state-layer-color: rgba(120, 198, 190, 0.2);
  --mdc-slider-handle-color: #78c6be;
  --mdc-slider-focus-handle-color: #78c6be;
  --mdc-slider-hover-handle-color: #78c6be;
  --mdc-slider-active-track-color: #78c6be;
  --mdc-slider-inactive-track-color: #78c6be;
  --mdc-slider-with-tick-marks-inactive-container-color: #78c6be;
  --mdc-slider-with-tick-marks-active-container-color: #fafafa;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Noto Sans TC;
  --mdc-slider-label-label-text-size: 1rem;
  --mdc-slider-label-label-text-line-height: 1.5;
  --mdc-slider-label-label-text-weight: 400;
}

.mdc-switch {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-state-layer-size: 40px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #6d4c41;
  --mdc-switch-selected-handle-color: #6d4c41;
  --mdc-switch-selected-hover-state-layer-color: #6d4c41;
  --mdc-switch-selected-pressed-state-layer-color: #6d4c41;
  --mdc-switch-selected-focus-handle-color: #3e2723;
  --mdc-switch-selected-hover-handle-color: #3e2723;
  --mdc-switch-selected-pressed-handle-color: #3e2723;
  --mdc-switch-selected-focus-track-color: #a1887f;
  --mdc-switch-selected-hover-track-color: #a1887f;
  --mdc-switch-selected-pressed-track-color: #a1887f;
  --mdc-switch-selected-track-color: #a1887f;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #70c0b8;
  --mdc-switch-selected-handle-color: #70c0b8;
  --mdc-switch-selected-hover-state-layer-color: #70c0b8;
  --mdc-switch-selected-pressed-state-layer-color: #70c0b8;
  --mdc-switch-selected-focus-handle-color: #48a499;
  --mdc-switch-selected-hover-handle-color: #48a499;
  --mdc-switch-selected-pressed-handle-color: #48a499;
  --mdc-switch-selected-focus-track-color: #a1d7d2;
  --mdc-switch-selected-hover-track-color: #a1d7d2;
  --mdc-switch-selected-pressed-track-color: #a1d7d2;
  --mdc-switch-selected-track-color: #a1d7d2;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #795548;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #795548;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #795548;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #fafafa;
  --mat-stepper-header-selected-state-icon-background-color: #78c6be;
  --mat-stepper-header-selected-state-icon-foreground-color: #fafafa;
  --mat-stepper-header-done-state-icon-background-color: #78c6be;
  --mat-stepper-header-done-state-icon-foreground-color: #fafafa;
  --mat-stepper-header-edit-state-icon-background-color: #78c6be;
  --mat-stepper-header-edit-state-icon-foreground-color: #fafafa;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #78c6be;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Noto Sans TC;
  --mat-table-header-headline-line-height: 1.5;
  --mat-table-header-headline-size: 1rem;
  --mat-table-header-headline-weight: 400;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #795548;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #795548;
  --mat-tab-header-active-ripple-color: #795548;
  --mat-tab-header-inactive-ripple-color: #795548;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #795548;
  --mat-tab-header-active-hover-label-text-color: #795548;
  --mat-tab-header-active-focus-indicator-color: #795548;
  --mat-tab-header-active-hover-indicator-color: #795548;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #78c6be;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #78c6be;
  --mat-tab-header-active-ripple-color: #78c6be;
  --mat-tab-header-inactive-ripple-color: #78c6be;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #78c6be;
  --mat-tab-header-active-hover-label-text-color: #78c6be;
  --mat-tab-header-active-focus-indicator-color: #78c6be;
  --mat-tab-header-active-hover-indicator-color: #78c6be;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #795548;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #78c6be;
  --mat-tab-header-with-background-foreground-color: #fafafa;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Noto Sans TC;
  --mat-tab-header-label-text-size: 1rem;
  --mat-tab-header-label-text-line-height: 1.5;
  --mat-tab-header-label-text-weight: 400;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #795548;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #78c6be;
  --mat-toolbar-container-text-color: #fafafa;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Noto Sans TC;
  --mdc-plain-tooltip-supporting-text-size: 0.8rem;
  --mdc-plain-tooltip-supporting-text-weight: 500;
}

.title {
  font-size: 1.953125rem;
  font-weight: 500;
  line-height: 1.25;
  font-family: Noto Sans TC;
}

.mat-mdc-dialog-title, .chapter {
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.25;
  font-family: Noto Sans TC;
}

.section1, .meeting-section1 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  font-family: Noto Sans TC;
}

td,
.mat-mdc-cell, .section2, body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Noto Sans TC;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Noto Sans TC;
}

th,
.mat-mdc-header-cell,
code,
.bullet {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.25;
  font-family: Noto Sans TC;
}

* {
  font-family: "Roboto", "Noto Sans TC", sans-serif;
}

.title {
  margin-bottom: 3rem;
  color: #5d4037;
}
.title > span {
  color: #a1887f;
  margin-left: 8px;
}
.title .mdc-icon-button {
  color: #757575;
}

.chapter {
  margin-bottom: 1rem;
}

.mat-mdc-dialog-title {
  margin: 0;
}

.section1, .meeting-section1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.section1 span.subtitle, .meeting-section1 span.subtitle {
  margin-left: 1rem;
}

.meeting-section1 {
  padding: 1rem 1rem 1rem 1.5rem;
  margin-top: 3rem;
  background-color: #efebe9;
  border-left: 16px solid #795548;
  color: #795548;
}

.section2 {
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.content-text {
  font-weight: 400 !important;
}

.subtitle {
  color: #757575;
}

p {
  text-align: justify;
}

span > p {
  margin: 0;
}

.justify {
  text-align: justify;
}

/* 標籤設定 */
.mark-layout {
  padding: 4px;
}

.tag {
  display: inline-block;
  background-color: #eeeeee;
  color: #757575;
  padding: 0px 4px;
}

/* margin設定 */
.spac-top-0 {
  margin-top: 0 !important;
}

.spac-top-4 {
  margin-top: 4px !important;
}

.spac-bottom-4 {
  margin-bottom: 4px !important;
}

.spac-left-4 {
  margin-left: 4px !important;
}

.spac-right-4 {
  margin-right: 4px !important;
}

.spac-sides-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.spac-top-8 {
  margin-top: 8px !important;
}

.spac-bottom-8 {
  margin-bottom: 8px !important;
}

.spac-left-8 {
  margin-left: 8px !important;
}

.spac-right-8 {
  margin-right: 8px !important;
}

.spac-sides-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.spac-top-12 {
  margin-top: 12px !important;
}

.spac-bottom-12 {
  margin-bottom: 12px !important;
}

.spac-left-12 {
  margin-left: 12px !important;
}

.spac-right-12 {
  margin-right: 12px !important;
}

.spac-sides-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.spac-top-16 {
  margin-top: 16px !important;
}

.spac-bottom-16 {
  margin-bottom: 16px !important;
}

.spac-left-16 {
  margin-left: 16px !important;
}

.spac-right-16 {
  margin-right: 16px !important;
}

.spac-sides-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.spac-top-24 {
  margin-top: 24px !important;
}

.spac-bottom-24 {
  margin-bottom: 24px !important;
}

.spac-left-24 {
  margin-left: 24px !important;
}

.spac-right-24 {
  margin-right: 24px !important;
}

.spac-sides-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.spac-top-32 {
  margin-top: 32px !important;
}

.spac-bottom-32 {
  margin-bottom: 32px !important;
}

.spac-left-32 {
  margin-left: 32px !important;
}

.spac-right-32 {
  margin-right: 32px !important;
}

.spac-sides-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.spac-left-40 {
  margin-left: 40px !important;
}

.spac-top-44 {
  margin-top: 44px !important;
}

.spac-bottom-44 {
  margin-bottom: 44px !important;
}

.spac-left-44 {
  margin-left: 44px !important;
}

.spac-right-44 {
  margin-right: 44px !important;
}

.spac-top-3r {
  margin-top: 3rem !important;
}

.spac-bottom-3r {
  margin-bottom: 3rem !important;
}

.spac-left-3r {
  margin-left: 3rem !important;
}

.spac-top-64 {
  margin-top: 64px !important;
}

.spac-bottom-64 {
  margin-bottom: 64px !important;
}

.spac-left-64 {
  margin-left: 64px !important;
}

.spac-right-64 {
  margin-right: 64px !important;
}

.spac-top-6r {
  margin-top: 6rem !important;
}

.spac-bottom-6r {
  margin-bottom: 6rem !important;
}

/* padding設定 */
.pd-top-12 {
  padding-top: 12px !important;
}

.pd-8 {
  padding: 8px;
}

.pd-8-16 {
  padding: 8px 16px;
}

.pd-top-8 {
  padding-top: 8px !important;
}

.pd-bottom-8 {
  padding-bottom: 8px !important;
}

.spac-sides--16 {
  margin-left: -16px;
  margin-right: -16px;
}

/* 常用尺寸設定 */
.full {
  width: 100%;
}

.list_56 {
  height: 56px !important;
}

.list_72 {
  height: 56px !important;
}

/* flex 排版設定 */
.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap24 {
  gap: 24px;
}

.flex_default {
  display: flex !important;
  flex-direction: row;
}

.column_ifmini_reverse {
  flex-direction: column-reverse !important;
}

.flex_row_start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.flex_row_start_center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex_row_end_center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex_row_center {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex_row_between_start, .card-header-tool {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.flex_row_between_end {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.flex_row_between_center {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.panel-table .mat-panel-title, .panel-table > section {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  width: 100%;
}

.flex_row_stretch_start {
  display: flex !important;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
}

.flex_column_start {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex_column_start_center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex_column_center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* flex item 占比設定 */
.item0 {
  flex: 0 !important;
}

.item {
  flex: 1 !important;
}

.item-short-right {
  width: 2rem;
  padding: 0 0.5rem;
  text-align: right;
}

.text-right {
  text-align: right;
}

.item-short {
  width: 5rem !important;
  padding: 0 0.5rem;
}

.item-short3-left {
  width: 3rem !important;
}

.inline-block {
  display: inline-block;
}

.item-long-title {
  width: 12rem;
}

.item2 {
  flex: 2 !important;
}

.item3 {
  flex: 3 !important;
}

.item4 {
  flex: 4 !important;
}

.item5 {
  flex: 5 !important;
}

.item6 {
  flex: 6 !important;
}

.item7 {
  flex: 7 !important;
}

.item8 {
  flex: 8 !important;
}

/* 表格對齊 */
.align-top td {
  vertical-align: super;
}

.font-w-normal {
  font-weight: normal;
}

@media (max-width: 960px) {
  .flex_default {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .mobile-column {
    display: flex;
    flex-direction: column !important;
  }
  .mobile-column-end {
    flex-direction: column !important;
    align-items: flex-end !important;
  }
}
.icon-pointer {
  color: #98635e !important;
}
.icon-pointer::before {
  content: "☞";
  font-size: 1.5rem;
  margin-right: 8px;
  vertical-align: middle;
}

.indent-top-2 {
  vertical-align: text-top;
  margin-top: -2px;
}

.wd-1 {
  width: 1rem;
}

.wd-px-24 {
  width: 24px;
}

.wd-2 {
  width: 2rem;
}

.wd-2-5 {
  width: 2.5rem;
}

.wd-3 {
  width: 3rem;
}

.wd-3-5 {
  width: 3.5rem;
}

.wd-4 {
  width: 4rem;
}

.wd-5 {
  width: 5rem;
}

.wd-6 {
  width: 6rem;
}

.wd-8 {
  width: 8rem;
}

.wd-10 {
  width: 10rem;
}

/* ---------------
    按鈕類
 ---------------- */
/* 包含圖示的居中按鈕 */
.sent {
  margin: 3rem auto;
  width: 45%;
  max-width: 240px;
}
.sent button {
  width: 100%;
  height: 44px;
}
.sent button i,
.sent button img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.sent button span {
  display: inline-block;
}
.sent button + button {
  margin-top: 24px !important;
}

/* 通常用於 step切換步驟時 */
.btn_back {
  float: left;
  margin-top: 8px !important;
}

.btn_next {
  float: right;
  margin-top: 8px !important;
}

.table_button {
  vertical-align: middle;
  text-align: center;
}

.btn_border {
  border: 1px #fafafa solid !important;
  border-radius: 4px;
  color: #fafafa;
}

td .mat-mdc-icon-button + .mat-mdc-icon-button {
  margin-left: 16px;
}

.mat-mdc-unelevated-button.mat-accent .mdc-button__label,
.mat-mdc-raised-button.mat-accent .mdc-button__label {
  color: #fff;
}

.mat-mdc-fab.mat-accent .mat-icon,
.mat-mdc-mini-fab.mat-accent .mat-icon {
  color: #fff;
}

.sub-item {
  border-left: 4px solid transparent;
  padding-left: 1rem;
  margin-left: calc(-1rem - 4px);
}

.sub-item-indent {
  border-left: 4px solid transparent;
  padding-left: 1rem;
  margin-left: -4px;
}

.mat-expansion-panel-header .mat-content {
  overflow: inherit !important;
}

.highlight-border {
  border-color: #ffe57f !important;
}

.system-border {
  border-color: #e0e0e0 !important;
}

/*----- 卡片 -----*/
.mat-mdc-card {
  box-shadow: 1px 1px 4px 0 #e3e3e3 !important;
  overflow: hidden;
  padding: 16px;
}

.mat-mdc-card + .chapter {
  margin-top: 24px;
}

.clickable-card:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.card-header-tool button {
  color: #757575;
}

.dataView .section1 {
  color: #8d6e63;
}
.dataView .section1 + .section2 {
  margin-top: 1rem;
}
.dataView .section2 + .section2 {
  margin-top: 3rem;
  font-weight: bolder;
}

.mat-mdc-card + .mat-mdc-card,
.mat-card + app-record-list {
  display: block;
  margin-top: 24px !important;
}

.mat-mdc-card-header {
  position: relative;
}

.subTitle {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.subTitle span {
  flex: 1;
}
.subTitle h2 {
  flex: 1;
}
.subTitle button {
  color: #757575;
}

.mat-mdc-card-footer {
  padding: 1rem;
}

.mat-mdc-card-footer span + span {
  margin-left: 1rem;
}

.mat-mdc-card-actions {
  gap: 1rem;
}

.action-bar {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 64px;
}

.data_empty {
  margin: 24px auto !important;
  text-align: center;
  color: #757575;
}

.data_empty_td {
  border-bottom: 1px #eeeeee solid;
}

.data_review {
  padding: 0.5rem;
  background-color: #fff9f3;
  border-radius: 8px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-bottom: 1.5rem;
}

.data_ps {
  display: inline-block;
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: justify;
  text-indent: 0;
  margin-top: 8px;
}

mat-card.data_tip {
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  background-color: #cdeedb;
  text-align: justify;
  align-items: center;
}
mat-card.data_tip .material-icons {
  color: #006644;
}

mat-card.data-warn {
  display: flex !important;
  flex-direction: row;
  gap: 8px;
  color: #fff;
  background-color: #b71c1c;
  align-items: center;
}
mat-card.data-warn .mat-mdc-outlined-button {
  color: #fff;
  border-color: #fff;
}

.data_ps_bg {
  padding: 0.5rem;
  background-color: #eeeeee;
  border-radius: 4px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.data_ps_bg > .section2 {
  margin-top: 0;
}
.data_ps_bg > .section1 {
  margin-top: 0;
  color: #000;
}

.data_editor {
  padding: 0.5rem;
  border: 1px #e0e0e0 solid;
  border-radius: 4px;
  text-align: justify;
  text-justify: distribute;
  text-indent: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.data_editor > .section2 {
  margin-top: 0;
}

.data-container-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.li-ps-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.aeps_alert {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #fff8e1;
}
.aeps_alert > .item {
  display: flex !important;
  gap: 8px !important;
  text-align: justify;
  padding: 1rem 0.5rem;
}
.aeps_alert > .item .material-icons {
  color: #795548;
}

.disabled-click {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

/* 在不同尺寸下的設定 */
.mat-mdc-chip.abled {
  opacity: 1 !important;
}

.color-wait {
  background-color: #f6c871 !important;
}
.color-wait .mat-mdc-chip-action-label {
  color: #fff !important;
}

.chip-list-view {
  pointer-events: none;
}
.chip-list-view .color-red {
  color: #fff;
  background-color: #e57373;
}
.chip-list-view .color-blue {
  color: #fff;
  background-color: #64b5f6;
}

/*----- Dialog -----*/
.mat-mdc-dialog-actions {
  justify-content: space-between;
}

.full-dialog .ng-star-inserted {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.full-dialog .mat-mdc-dialog-content {
  flex: 1;
  max-height: inherit !important;
}

.dialog-tool-bar {
  flex: 0;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.dialog-tool-bar .up_button {
  margin: 0 !important;
}

/* 拖拉效果 */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/* 對正在排序的項目進行動畫處理 */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* 為已丟棄的項目設置動畫 */
.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

img {
  -webkit-user-drag: none;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 8px;
}
.gallery .gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}
.gallery .gallery-item .gallery-img {
  cursor: pointer;
  width: auto;
  height: 12rem;
}
.gallery .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.gallery img {
  width: auto;
  height: 12rem;
  object-fit: contain;
}
.gallery .cdk-drag-placeholder {
  opacity: 0.33;
}

.editor-view table {
  width: auto !important;
}
.editor-view table td {
  padding: 0 0.5rem;
}

.mat-accordion > section {
  padding: 0 24px 0 8px;
  background-color: #e9e9e9;
}
.mat-accordion .mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px #eeeeee solid;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  border-bottom: none !important;
  padding: 4px 16px 4px 8px;
  height: auto;
  min-height: 48px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-mdc-icon-button {
  color: #757575;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  border-top: 1px #eeeeee solid;
  background-color: #fafafa;
  font-size: 1rem;
  letter-spacing: normal;
}

.panel-table > section {
  height: 56px;
  color: #757575;
  white-space: nowrap;
}

.list-table-panel .mat-expansion-panel-header {
  padding: 4px 16px !important;
}
.list-table-panel .list-table-panel-header {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 8px;
  margin-right: 8px;
}

.expansion-card > .mat-expansion-panel {
  margin-top: 44px !important;
  box-shadow: none;
  background: transparent;
  border: none !important;
}
.expansion-card > .mat-expansion-panel > .mat-expansion-panel-header {
  padding-left: 8px;
  margin-bottom: 8px;
  height: auto !important;
}
.expansion-card > .mat-expansion-panel > .mat-expansion-panel-header .mat-expansion-panel-header-description {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 44px;
}
.expansion-card > .mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}

.panel-header-between .mat-expansion-panel-header-description {
  flex-grow: unset !important;
  flex-basis: unset !important;
}

.flex-clean {
  flex-grow: unset !important;
  flex-basis: auto !important;
}

.panel-border {
  border-top: 1px #eeeeee solid;
}

.field {
  display: flex;
  flex-direction: row;
  margin: 1rem auto;
  gap: 16px;
}
.field .field-name {
  flex-basis: 8rem;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: left;
  padding-top: 1rem;
  color: #757575;
}
.field .field-value {
  flex: 1;
  width: 100%;
  position: relative;
}
.field .field-value .value-item {
  display: flex;
  margin-right: 24px;
  gap: 4px;
}
.field .field-value .value-item .material-icons {
  padding-top: 1px;
  color: #8d6e63;
}

app-ques-viewer p {
  margin-block-start: 0 !important;
}

.field + .chapter {
  margin-top: 3rem;
}

.field-group {
  margin-top: 1.2rem;
  margin-bottom: 1.8rem;
}
.field-group + .field-group {
  margin-top: 3.6rem;
}
.field-group th {
  padding-right: 16px !important;
}
.field-group h4 {
  margin: 1rem 0 0 0;
  color: #8d6e63;
}

.field-only {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
}

.abreast {
  display: flex;
  gap: 8px;
}

.superscript {
  font-size: 0.6rem;
  vertical-align: super;
}

.field-checkbox {
  padding-top: 8px;
}
.field-checkbox .mdc-checkbox__checkmark {
  color: #fff !important;
}

.field-hint {
  margin-top: -1rem;
  font-size: 0.875rem;
}

/* 在不同尺寸下的設定 */
@media (max-width: 599.98px) {
  .field .field-name {
    color: #424242;
    font-weight: 500;
    font-size: 1.2rem;
    flex-basis: 0;
    margin-right: 0;
  }
  .field .field-value {
    order: 3;
    margin-left: 0;
    flex-basis: 1rem;
  }
}
.file-preview-item-container {
  display: flex;
  flex-direction: column;
}
.file-preview-item-container + .file-preview-item-container {
  margin-top: 0.5rem;
}

.file-preview-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.file-preview-item + .file-preview-item {
  margin-top: 0.5rem;
}
.file-preview-item .list-item-id {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 0.8rem;
  color: #a1887f;
}
.file-preview-item app-file-preview {
  flex: 1;
}

.material-icons {
  font-size: 1.25rem;
}

.mdc-button .mat-icon {
  height: auto !important;
}

td .mat-mdc-icon-button,
ul .mat-mdc-icon-button,
ol .mat-mdc-icon-button {
  color: #757575;
}

.mat-accent .mat-icon {
  color: #78c6be;
}

.drag_handle {
  color: #757575;
  cursor: move;
  margin-right: 8px;
}

.mat-icon {
  line-height: 1.375rem;
}

.mdc-tooltip__surface {
  text-align: left !important;
}

.description-step + .description-step {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid #e0e0e0;
}

.description-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
.description-layout .mat-mdc-card {
  cursor: pointer;
}
.description-layout .mat-mdc-card + .mat-mdc-card {
  margin-top: 0 !important;
}
.description-layout .mat-mdc-card.center {
  align-items: center !important;
}

/* XSmall - 小於600px */
@media (max-width: 599.98px) {
  .description-layout {
    grid-template-columns: repeat(1, 1fr);
  }
  .description-layout .mat-mdc-card {
    height: 13rem;
  }
}
/* Small - 大於等於600，小於960 (包含Tablet)
   在此處為了題目最佳呈現效果，將寬度設為600-1120之間
*/
@media (min-width: 600px) and (max-width: 1119.98px) {
  .description-layout .mat-mdc-card {
    height: 12rem;
  }
}
/* Medium	 - 大於等於960，小於1280
   在此處為了題目最佳呈現效果，將寬度設為1120-1120之間
*/
@media (min-width: 1120px) and (max-width: 1279.98px) {
  .description-layout .mat-mdc-card {
    height: 9rem;
  }
}
/* Large - 大於1280 */
@media (min-width: 1280px) {
  .description-layout .mat-mdc-card {
    height: 8rem;
  }
}
li::marker,
.marker {
  color: #a1887f;
}

.marker {
  color: #a1887f;
  margin-right: 12px;
  width: 1.5rem;
  display: inline-block;
  text-align: right;
}

ol {
  padding-inline-start: 1rem;
  margin: 0 0 0 4px;
}
ol li {
  padding-left: calc(1rem - 4px);
  margin-bottom: 0.25rem;
}

mat-stepper ol {
  padding-left: 1.5rem !important;
}

ul {
  padding-inline-start: 1rem;
  margin: 0;
  list-style-type: "◼";
}
ul li {
  padding-left: 1rem;
  margin-bottom: 0.25rem;
}

.no-marker {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
}
.no-marker li {
  padding-left: 0;
}
.no-marker .li-bullet {
  width: 4rem;
  color: #98635e;
  display: inline-block;
  margin-right: 4px;
}

.li-viewer {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.li-marker {
  color: #a1887f;
  display: inline-block;
}

.iconlist,
.iconlist-nohover {
  padding-left: 16px;
}
.iconlist ol,
.iconlist-nohover ol {
  margin-top: 1rem;
  margin-bottom: 24px;
}
.iconlist ol li,
.iconlist-nohover ol li {
  text-align: justify;
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 40px;
  margin-bottom: 16px;
}
.iconlist ol li .btn-bullet,
.iconlist-nohover ol li .btn-bullet {
  display: none;
  background-color: #fafafa !important;
  position: absolute;
  left: -40px;
  top: -10px;
  color: #757575;
}
.iconlist ol li th,
.iconlist-nohover ol li th {
  white-space: nowrap;
}
.iconlist ol li label:hover,
.iconlist-nohover ol li label:hover {
  cursor: pointer;
}
.iconlist ol li label:hover div,
.iconlist-nohover ol li label:hover div {
  background-color: whitesmoke;
}
.iconlist ol li:hover button,
.iconlist-nohover ol li:hover button {
  display: block;
}

.editable-list {
  padding-left: 16px;
}
.editable-list ul,
.editable-list ol {
  margin-top: 1rem;
  margin-bottom: 24px;
}
.editable-list ul li,
.editable-list ol li {
  text-align: justify;
  position: relative;
  margin-bottom: 8px;
}
.editable-list ul li .btn-bullet,
.editable-list ol li .btn-bullet {
  display: none;
  background-color: #fff !important;
  position: absolute;
  top: -12px;
  left: -28px;
  color: #757575;
}
.editable-list ul li label div,
.editable-list ol li label div {
  padding: 2px 0.75rem;
}
.editable-list ul li label:hover,
.editable-list ol li label:hover {
  cursor: pointer;
}
.editable-list ul li label:hover div,
.editable-list ol li label:hover div {
  background-color: whitesmoke;
}
.editable-list ul li:hover button,
.editable-list ol li:hover button {
  display: block;
}

.reference li,
.reference a {
  color: #a1887f;
}
.reference li {
  list-style-type: none;
}

.no-marker {
  padding-left: 0 !important;
}

.add-multi {
  width: 100%;
  display: flex;
  gap: 8px;
}
.add-multi app-text-multi-lines {
  flex: 1;
}
.add-multi .li-bullet {
  margin-top: 1.1rem;
  color: #98635e;
  display: inline-block;
}

.drag-text-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}
.drag-text-list .add-multi .drag_handle {
  margin-top: 0.875rem;
}
.drag-text-list .add-multi .li-marker,
.drag-text-list .add-multi .drag-text-preview {
  margin-top: 1rem;
}

.multi-view {
  margin: 1rem 0;
  background-color: darkgoldenrod;
}
.multi-view .drag_handle {
  margin-top: -2px !important;
}
.multi-view .li-marker {
  margin-top: 0;
}
.multi-view .drag-text-preview {
  margin-top: 0;
}

@media (max-width: 600px) {
  .drag-text-list {
    flex-direction: column;
    margin-bottom: 1rem;
  }
}
.list-table .mat-mdc-subheader {
  display: flex;
  font-weight: bold;
  margin: 0 !important;
}
.list-table .mat-mdc-list-item + .mat-mdc-list-item {
  border-top: 1px solid #eeeeee;
}
.list-table .mat-mdc-list-item {
  height: auto !important;
  padding: 0.25rem 0;
  min-height: 48px;
}
.list-table span {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.list-table span span {
  flex: 1;
}
.list-table .panel-icon-spac {
  flex: none;
  width: 8px;
}

.list-tool {
  text-align: center;
  width: 40px;
}
.list-tool + .list-tool {
  margin-left: 16px;
}

/*----- list -----*/
.underline .mat-mdc-list-item + .mat-mdc-list-item {
  border-top: 1px solid #eeeeee;
}
.underline + .underline {
  border-top: 1px solid #eeeeee;
}

.mat-mdc-list-item a {
  height: 100%;
}

.list-more a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-list .mat-mdc-list-item:hover {
  background: whitesmoke !important;
  cursor: pointer;
}

.checkbox-list .mdc-list-item__primary-text {
  display: flex;
  width: 100%;
}

.checkbox-list-title span {
  color: #8d6e63 !important;
}

.checkbox-list-item span {
  padding-left: 24px !important;
}

.panel-list .mat-expansion-panel-header {
  height: 48px;
}
.panel-list .mat-expansion-panel-body {
  padding: 0 0 1rem;
}

.mat-mdc-selection-list .mat-mdc-list-item {
  height: auto !important;
  padding-left: 0;
}
.mat-mdc-selection-list .mdc-list-item__primary-text {
  white-space: pre-wrap;
  text-align: justify;
  display: inline-block;
  padding-top: 8px;
}
.mat-mdc-selection-list .mdc-list-item__start,
.mat-mdc-selection-list .mdc-list-item__end {
  align-self: flex-start !important;
  margin: 0 !important;
}
.mat-mdc-selection-list .mdc-checkbox__checkmark {
  color: #fff !important;
}

.auto-line.mat-mdc-list-item {
  height: auto !important;
}

.mdc-list-item__primary-text > span,
.mat-mdc-subheader > span {
  padding: 4px;
}

.photo-proof {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 1rem;
}
.photo-proof .proof-frame {
  flex: 1;
}

@media (max-width: 600px) {
  .photo-proof {
    flex-direction: column;
  }
}
mat-stepper + .mat-mdc-card {
  margin-top: 24px;
}

.mat-step-header.cdk-program-focused {
  background-color: transparent;
}
.mat-step-header .mat-step-icon {
  background-color: #bdbdbd;
}
.mat-step-header .mat-step-icon-selected {
  background-color: #795548 !important;
}

.mat-horizontal-stepper-header {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.disable-header-nav .mat-horizontal-stepper-header {
  pointer-events: none !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}
.mat-horizontal-content-container .mat-mdc-dialog-actions {
  min-height: 64px !important;
}
.mat-horizontal-content-container .mat-mdc-card-actions {
  padding-left: 8px;
  padding-right: 8px;
}

.mat-stepper-horizontal-line {
  margin: 0 -8px !important;
}

.mat-vertical-stepper-header {
  padding: 16px !important;
}

.mat-vertical-content {
  padding: 0 0 24px 24px !important;
}

.mat-stepper-vertical-line {
  margin: 8px 0;
}
.mat-stepper-vertical-line::before {
  left: -8px !important;
}

.td_photo {
  width: 56px;
  height: 56px;
  object-fit: cover;
  background-color: #faf8f6;
  margin: 8px 0;
}

.td_photo img {
  max-width: 100%;
  max-height: 100%;
}

.table-rwd-title {
  background-color: #e0e0e0;
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

tr.table-hover-row:hover {
  background: whitesmoke !important;
  cursor: pointer;
}

tr.table-hover-row:active {
  background: #efefef !important;
}

.table-hover-row td {
  border-bottom-width: 0 !important;
}

.table-underline tr td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.table-underline tr + tr td {
  border-top: 1px solid #eeeeee !important;
}

/* 表格查無結果時的樣式*/
.null-cell {
  text-align: center;
  white-space: nowrap;
  padding: 0 8px;
  height: 4.5rem;
}

@media (max-width: 780px) {
  .rwd-table .mat-mdc-header-row {
    display: none;
  }
  .rwd-table .mat-mdc-row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
  }
  .rwd-table .mat-mdc-row .mat-mdc-cell {
    display: flex;
    padding: 0.5rem 1rem;
  }
  .rwd-table .mat-mdc-row .mat-mdc-cell:first-of-type {
    padding-left: 8px;
  }
}
.grayscale-table {
  border-top: 1px #e9e9e9 solid;
  border-left: 1px #e9e9e9 solid;
  border-right: 1px #e9e9e9 solid;
}
.grayscale-table .mat-mdc-header-cell {
  background-color: #e9e9e9 !important;
  text-align: center;
  white-space: nowrap;
  padding: 0 8px;
  border-bottom: 1px #e9e9e9 solid;
}
.grayscale-table .mat-mdc-cell {
  text-align: center;
  white-space: nowrap;
  padding: 0 8px;
  height: 3rem;
}
.grayscale-table .mat-mdc-cell + .mat-mdc-cell {
  border-left: 1px #e9e9e9 solid;
}
.grayscale-table .border-right {
  border-right: 1px solid #bdbdbd !important;
}
.grayscale-table .border-bottom {
  border-bottom: 1px solid #bdbdbd !important;
}

.align-top td {
  vertical-align: baseline;
}

@media (max-width: 780px) {
  .table-underline tr + tr td {
    border-top: 0 !important;
  }
  .mobile-hightlight {
    background-color: #e0e0e0 !important;
  }
}
.outline {
  border: 1px #e9e9e9 solid !important;
}

.vertical_tab {
  display: flex !important;
  flex-direction: row !important;
  padding-bottom: 6rem;
}
.vertical_tab .tablist {
  width: 20%;
  min-width: 5.4rem;
  background-color: #e0e0e0;
  float: left;
  color: #212121;
}
.vertical_tab .tablist .tablist-title {
  padding: 16px 0 4px 4px;
  text-indent: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.54);
  cursor: auto;
}
.vertical_tab .tablist ul {
  list-style-type: none;
  padding: 0;
  margin: 0 !important;
}
.vertical_tab .tablist li {
  cursor: pointer;
  padding: 8px 8px 8px 32px;
  text-align: left;
  text-indent: -4px;
  margin-bottom: 0;
}
.vertical_tab .tablist li:before {
  content: "";
  display: none;
}
.vertical_tab .tablist li .bullet {
  color: #98635e;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
  margin-right: 4px;
}
.vertical_tab .column_right {
  padding-left: 3rem;
  width: 100%;
}
.vertical_tab .active {
  cursor: pointer;
  background-color: #fafafa;
}

.mat-mdc-card.vertical_tab {
  padding: 0 !important;
}
.mat-mdc-card.vertical_tab .column_right {
  padding: 1rem !important;
}

.option-num {
  color: #757575;
  width: 2rem;
}

.on-device {
  display: none !important;
  margin-bottom: 1.5rem;
}

@media (max-width: 600px) {
  .tablist {
    display: none;
  }
  .column_right {
    padding-left: 0 !important;
  }
  .on-device {
    display: block !important;
  }
}
/* 上傳題共用樣式 */
.up_frame {
  width: 100%;
  border-radius: 10px;
  border: 2px #eeeeee solid;
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 1rem 0;
}

.up_range {
  height: 100%;
  border-radius: 10px;
  border: 3px #eeeeee dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #9e9e9e;
  flex-direction: column;
}

.up_button {
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  border-radius: 4px;
  background-color: #78c6be;
  color: #fff;
  margin: 1rem auto;
}