@use 'src/scss/themes/_custom-color.scss' as customcolor;

// 使用list模擬table

.list-table {
  .mat-mdc-subheader {
    display: flex;
    font-weight: bold;
    margin: 0 !important;
  }

  .mat-mdc-list-item + .mat-mdc-list-item {
    border-top: 1px solid customcolor.$system-divider;
  }

  .mat-mdc-list-item {
    height: auto !important;
    padding: 0.25rem 0;
    min-height: 48px;
  }

  span {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      // color: customcolor.$system-dark;
      flex: 1;
    }
  }

  .panel-icon-spac {
    flex: none;
    width: 8px;
  }
}

.list-tool {
  text-align: center;
  width: 40px;

  + .list-tool {
    margin-left: 16px;
  }
}
